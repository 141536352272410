var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-bottom-navigation",
        { attrs: { fixed: "", value: _vm.nav, color: "primary", grow: "" } },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary", text: "" },
              on: {
                click: function ($event) {
                  return _vm.changeTab(0)
                },
              },
            },
            [
              _c("span", [_vm._v("Pending")]),
              _c("v-icon", [_vm._v("mdi-account-alert")]),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", text: "" },
              on: {
                click: function ($event) {
                  return _vm.changeTab(1)
                },
              },
            },
            [
              _c("span", [_vm._v("Upcoming")]),
              _c("v-icon", [_vm._v("mdi-calendar")]),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", text: "" },
              on: {
                click: function ($event) {
                  return _vm.changeTab(2)
                },
              },
            },
            [
              _c("span", [_vm._v("Completed")]),
              _c("v-icon", [_vm._v("mdi-check")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "list-wrapper", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _vm.loading
                ? _c("v-progress-circular", {
                    staticClass: "progress",
                    attrs: { size: 50, color: "primary", indeterminate: "" },
                  })
                : _vm.activeAssignments.length
                ? _vm._l(_vm.activeAssignments, function (a) {
                    return _c(
                      "v-col",
                      { key: a.id, attrs: { cols: "12" } },
                      [
                        _c("assignment-list", {
                          staticClass: "d-flex d-sm-none",
                          attrs: {
                            assignment: a,
                            tripRequest: _vm.tripRequests.find(
                              (e) => e.id == a.tripRequestId
                            ),
                          },
                        }),
                        _c("assignment", {
                          staticClass: "d-none d-sm-flex",
                          attrs: {
                            assignmentProp: a,
                            allExpanded: false,
                            specialIndicators: _vm.specialIndicators,
                            config: _vm.config,
                          },
                          on: { refresh: _vm.refresh },
                        }),
                      ],
                      1
                    )
                  })
                : _c("v-col", { attrs: { cols: "12" } }, [
                    _c("div", { staticClass: "text-subtitle-1 text-center" }, [
                      _vm._v(
                        "No " + _vm._s(_vm.types[_vm.nav]) + " assignments yet"
                      ),
                    ]),
                  ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition",
          },
          model: {
            value: _vm.driverTableDialog,
            callback: function ($$v) {
              _vm.driverTableDialog = $$v
            },
            expression: "driverTableDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "primary" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          _vm.driverTableDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("v-toolbar-title", [_vm._v("Driver Table")]),
                ],
                1
              ),
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [_c("driver-table")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }