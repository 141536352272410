var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-badge",
    {
      staticClass: "full-width",
      attrs: {
        value: !!_vm.assignment.needsAttention,
        color: "error",
        icon: "mdi-exclamation-thick",
        left: "",
        overlap: "",
        bordered: "",
      },
    },
    [
      _c(
        "v-row",
        [
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12", lg: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-4" },
                [
                  _c(
                    "v-container",
                    {
                      staticClass: "pa-0",
                      class: `${this.status.color.split(" ")[0]}-border `,
                      attrs: { fluid: "" },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "ma-0 clickable",
                          class: `${this.status.color} white--text ${
                            this.status.color.split(" ")[0]
                          }-border-bottom`,
                          on: {
                            click: function ($event) {
                              return _vm.$router.push(
                                `/driver/${_vm.assignment.id}`
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c("div", { staticClass: "text-subtitle-1" }, [
                                _vm._v("TRIP #"),
                              ]),
                              _c("div", { staticClass: "text-h5" }, [
                                _vm._v(_vm._s(_vm.assignment.tripRequestId)),
                              ]),
                              _vm.assignment.outOfState
                                ? _c(
                                    "v-icon",
                                    {
                                      class: `${
                                        _vm.status.color.split(" ")[0]
                                      }--text text--${
                                        _vm.status.color.split(" ")[1]
                                      } mx-1`,
                                    },
                                    [_vm._v(" mdi-weather-night ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { staticClass: "right", attrs: { cols: "8" } },
                            [
                              _c(
                                "div",
                                { staticClass: "text-subtitle-1 text-right" },
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v("mdi-account"),
                                  ]),
                                  _vm._v(_vm._s(_vm.driver)),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "text-subtitle-1 text-right" },
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v("mdi-bus"),
                                  ]),
                                  _vm._v(_vm._s(_vm.vehicle)),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "px-2 my-2" },
                        [
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _c("div", { staticClass: "text-subtitle-1" }, [
                              _vm._v(
                                _vm._s(_vm.leaveDate) +
                                  " - " +
                                  _vm._s(_vm.leaveTime)
                              ),
                            ]),
                            _c("div", { staticClass: "text-subtitle-1" }, [
                              _vm._v(
                                _vm._s(_vm.returnDate) +
                                  " - " +
                                  _vm._s(_vm.returnTime)
                              ),
                            ]),
                          ]),
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "text-subtitle-1 text-right" },
                              [_vm._v(_vm._s(_vm.tripHours) + " TOTAL HRS")]
                            ),
                            _c(
                              "div",
                              { staticClass: "text-subtitle-1 text-right" },
                              [_vm._v("TOTAL MI")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        { staticClass: "px-2 my-2" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "center-vertical",
                              attrs: { cols: "5" },
                            },
                            [
                              _c("div", { staticClass: "text-subtitle-1" }, [
                                _vm._v(_vm._s(_vm.locationName)),
                              ]),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "center-vertical",
                              attrs: { cols: "2" },
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: this.status.color } },
                                [_vm._v("mdi-arrow-right")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "center-vertical",
                              attrs: { cols: "5" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "text-subtitle-1 text-right" },
                                [_vm._v(_vm._s(_vm.destinationName))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-spacer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }