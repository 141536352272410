<template>
  <v-dialog v-model="modal" width="360px" no-click-animation persistent :disabled="readonly">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :dense="dense"
        :disabled="disabled"
        :error="error"
        :hide-details="hideDetails"
        :hint="hint"
        :label="label"
        :outlined="outlined"
        :persistent-hint="persistentHint"
        :required="required"
        :rules="rules"
        @click="resetValidation"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        ref="timePicker"
        v-bind="attrs"
        v-model="showTime"
        v-on="on"
        :data-cy="`time-picker-input-${label}`"
      ></v-text-field>
    </template>

    <v-card class="pt-5">
      <v-card-text>
        <div class="d-flex justify-space-between mb-6">
          <v-select
            v-model="selectTime.hour"
            :items="hours"
            outlined
            menu-props="auto"
            append-icon=""
            :data-cy="`time-picker-hour-${label}`"
          ></v-select>

          <div class="text-h4" style="padding-top: 10px">:</div>

          <v-select
            v-model="selectTime.minute"
            :items="minutes"
            outlined
            menu-props="auto"
            append-icon=""
            :data-cy="`time-picker-minute-${label}`"
          ></v-select>

          <v-btn-toggle v-model="selectTime.ampm" mandatory style="padding: 4px 0px 0px 2px">
            <v-btn value="am">AM</v-btn>
            <v-btn value="pm">PM</v-btn>
          </v-btn-toggle>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="modal = false">Cancel</v-btn>

        <v-spacer></v-spacer>

        <v-btn color="primary" @click="saveTime">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TimePicker',
  props: {
    dense: Boolean,
    disabled: Boolean,
    error: Boolean,
    hideDetails: [Boolean, String],
    hint: String,
    label: String,
    outlined: Boolean,
    persistentHint: Boolean,
    readonly: Boolean,
    required: Boolean,
    rules: Array,
    value: String,
  },
  data() {
    return {
      hours: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      minutes: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
      modal: false,
    };
  },
  computed: {
    showTime() {
      if (this.value) {
        const { hour, minute, ampm } = this.formatValue();

        return `${hour}:${minute} ${ampm}`;
      } else return '';
    },
    selectTime() {
      if (this.value) {
        return this.formatValue();
      } else
        return {
          hour: '1',
          minute: '00',
          ampm: 'am',
        };
    },
  },
  methods: {
    formatValue() {
      const s = this.value.split(':');

      let hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);

      const minute = s[1];
      const ampm = s[0] >= 12 ? 'pm' : 'am';

      if (hour == '0') hour = '12';

      return { hour, minute, ampm };
    },
    resetValidation() {
      this.$refs['timePicker'].resetValidation();
    },
    saveTime() {
      const ampm = this.selectTime.ampm;

      let hour = Number(this.selectTime.hour);

      if (ampm == 'am' && hour == 12) hour = '0';
      if (ampm == 'pm' && hour < 12) hour = String(hour + 12);

      const timeText = `${hour}:${this.selectTime.minute}`;

      this.$emit('input', timeText);
      this.validate();

      this.modal = false;
    },
    validate() {
      this.$refs['timePicker'].validate(true);
    },
  },
};
</script>
